import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import TextCard from '../components/textCard';
import { GoPrimitiveDot } from 'react-icons/go';
import Button from '../components/button';
import Link from '../components/betterLink';
import SEO from '../components/seo';
import HtmlReactParser from 'html-react-parser';
import breakpoint from 'styled-components-breakpoint';

const TextCardWrapper = styled.div`
    padding: 60px 0 30px 0;
    & + & {
        padding: 0 0 30px 0;
    }
`;

const Banner = styled(Img)`
    width: 100%;
    height: 300px;
`;

const Services = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 20px;
    ${breakpoint('tablet')`
      padding: 0 50px;
    `}
    min-height: 210px;
`;

const Bullet = styled(GoPrimitiveDot)`
    padding: 0 5px;
`;

const ContactButton = styled(Button)`
    display: block;
    margin: 15px auto 120px auto;
`;

const About = ({data})=>{
    const { aboutBanner } = data.setting.edges[0].node.frontmatter;
    const companyDescription = data.companyDescription.edges[0].node.html;
    const concept = data.concept.edges[0].node.html;
    const services = data.services.edges.sort((lhs,rhs)=>lhs.node.frontmatter.order - rhs.node.frontmatter.order);
    return(
        <div>
            <SEO title="關於"/>
            <Banner fluid={aboutBanner.childImageSharp.fluid}/>
            <TextCardWrapper>
                <TextCard 
                    title="公司介紹" 
                    description={(
                      <Services>
                        {HtmlReactParser(companyDescription)}
                      </Services> 
                    )}
                />
            </TextCardWrapper>
            <TextCardWrapper>
                <TextCard 
                    title="服務項目" 
                    description={(
                        <Services>
                            {services.map(({node},index)=>(
                                <div key={index}>
                                    <Bullet size="10px"/>{node.frontmatter.title}
                                </div>
                            ))}
                        </Services>
                    )}
                />
            </TextCardWrapper>
            <TextCardWrapper>
                <TextCard 
                    title="經營理念" 
                    description={(
                      <Services>
                        {HtmlReactParser(concept)}
                      </Services> 
                    )}
                />
            </TextCardWrapper>
            <Link to="/contact">
              <ContactButton text="聯絡我們"/>
            </Link>
        </div>
    );
};

export default About;

export const query = graphql`
  query {
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            aboutBanner{
              childImageSharp{
                fluid(maxWidth: 2048){
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    companyDescription: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "companyDescription" } }
    }){
      edges{
        node{
          html
        }
      }
    }
    concept: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "concept" } }
    }){
      edges{
        node{
          html
        }
      }
    }
    services: allMarkdownRemark( filter: { 
        frontmatter:{ templateKey:{ eq: "service" } }
    }){
      edges{
        node{
          frontmatter{
            title
            order
          }
        }
      }
    }
  }
`